<template>
  <div class="wrapper">
    <aside id="sidenav-open" :class="{ open: showSidenav }">
      <div id="nav-container" v-bind:style="asideStyle">
        <Biography />
        <nav id="items">
          <router-link to="/">Berufsphilosophie</router-link>
          <router-link to="/kinesiologie">Kinesiologie</router-link>
          <router-link to="/systemisches_arbeiten">
            Systemisches Arbeiten
          </router-link>
          <router-link to="/tension_trauma">Tension &amp; Trauma</router-link>
          <router-link to="/reeducating">Reeducating I.D.</router-link>
          <router-link to="/impressum">Impressum</router-link>
        </nav>
      </div>
      <a
        ref="closenav"
        href="#"
        id="sidenav-close"
        title="Close Menu"
        aria-label="Close Menu"
      ></a>
    </aside>

    <main class="main">
      <header :style="{ display: showNavButton ? 'block' : 'none' }">
        <menu-icon
          :isSidenavShown="showSidenav"
          v-on:toggle-sidenav="toggleSidenav"
        />
      </header>

      <article>
        <section>
          <router-view />
        </section>
      </article>
    </main>
  </div>
</template>

<script>
import Biography from "./components/Biography.vue";
import MenuIcon from "./components/menuIcon.vue";

export default {
  components: { Biography, MenuIcon },

  data: function () {
    return {
      showNavButton: true,
      showSidenav: false,
      observer: null,
    };
  },
  watch: {
    $route: function (to) {
      this.showSidenav = to.hash === "#sidenav-open";
    },
  },
  created() {
    this.observer = new ResizeObserver(() => {
      this.showNavButton = window.innerWidth < 769;
    });
  },

  mounted() {
    this.observer.observe(document.getElementsByClassName("wrapper")[0]);
  },
  unmounted() {
    this.observer.disconnect();
  },

  computed: {
    asideStyle: function () {
      let color = "";

      switch (this.$router.currentRoute.value.name) {
        case "Kinesiologie":
          color = "#33b1a4";
          break;
        case "Systemisches_Arbeiten":
          color = "rgb(61, 116, 50)";
          break;
        case "Tension_Trauma":
          color = "rgb(161, 163, 7)";
          break;
        case "Impressum":
          color = "#8b008b";
          break;
        default:
          color = "#5e402e";
          break;
      }

      return {
        "background-color": color,
      };
    },
  },

  methods: {
    close() {
      document.location.hash = "";
    },
    transition() {
      document.location.hash === "#sidenav-open";
    },
    toggleSidenav(value) {
      this.showSidenav = value;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

$widthBreakpoint: 769px;

body {
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  padding: 12px 0 6px 0;
  text-align: center;
  font-family: "Roboto Slab", serif;
}

p {
  margin: 0;
  padding: 6px 12px 6px 12px;
  text-align: left;
}

ul,
li {
  text-align: left;
}

.wrapper {
  display: grid;
  grid: [stack] 1fr / min-content [stack] 2fr;

  @media (max-width: $widthBreakpoint) {
    & > :is(aside, main) {
      grid-area: stack;
    }
  }
}

article p {
  padding-left: 12px;
}
article div.img {
  display: flex;
  justify-content: center;
  align-items: center;
}

#sidenav-open {
  --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
  --duration: 0.6s;

  display: grid;
  grid-template-columns: [nav] 350px [escape] 1fr;

  @media (max-width: $widthBreakpoint) {
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow: hidden auto;
    overscroll-behavior: contain;

    visibility: hidden; /* not keyboard accessible when closed */
    transform: translateX(-110vw);
    will-change: transform;
    transition: transform var(--duration) var(--easeOutExpo),
      visibility 0s linear var(--duration);

    &.open {
      visibility: visible;
      transform: translateX(0);
      transition: transform var(--duration) var(--easeOutExpo);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    --duration: 1ms;
  }
}

#nav-container {
  transition: all 1s ease;
  background-color: #5e402e;
  text-align: right;
  color: white;

  nav a,
  nav a:visited {
    border-top: 1px solid white;
    padding: 6px 6px 6px 0;
    font-weight: bold;
    color: #000;

    &.router-link-exact-active,
    &:hover {
      background-color: white;
      color: #42b983;
    }
  }

  @media (min-width: $widthBreakpoint) {
    display: block;
    min-width: 300px;
    position: sticky;
    top: 0;
  }
}

#sidenav-button,
#sidenav-close {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: manipulation;

  @media (min-width: $widthBreakpoint) {
    display: none;
  }
}

header {
  text-align: right;
}

#image {
  opacity: 0.5;
  z-index: 0;
  height: 0px;
  position: relative;
}

aside img {
  width: 100%;
}

#items {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
}
</style>
