import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/kinesiologie",
    name: "Kinesiologie",
    component: () =>
      import(/* webpackChunkName: "kinesiologie" */ "../views/Kinesiologie.vue")
  },
  {
    path: "/systemisches_arbeiten",
    name: "Systemisches_Arbeiten",
    component: () =>
      import(
        /* webpackChunkName: "systemisches_arbeiten" */ "../views/Systemisches_Arbeiten.vue"
      )
  },
  {
    path: "/tension_trauma",
    name: "Tension_Trauma",
    component: () =>
      import(
        /* webpackChunkName: "tension_trauma_release" */ "../views/Tension_Trauma_Release.vue"
      )
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue")
  },
  {
    path: "/reeducating",
    name: "ReEducating ID",
    component: () =>
      import(/* webpackChunkName: "reeducating" */ "../views/ReeducatingID.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
