<template>
  <div class="img">
    <img
      src="../assets/Balanceraeume.png"
      alt="Ingird Dünnewald, Praxis Balanceräume"
      width="500"
    />
  </div>
  <h1>Ingrid D&uuml;nnewald</h1>
  <p>Ich begleite und berate Menschen in verschiedenen Bereichen des Lebens.</p>

  <h2>Schwangerschaft, Geburt und Säuglingszeit</h2>
  <ul>
    <li>Jegliche Art Stressbelastungen in der Schwangerschaft</li>
    <li>
      Integration von schweren, (subjektiv) negativ erlebten/verlaufenen
      Geburten, Fehl- und Totgeburten
    </li>
    <li>Herausforderungen in der Säuglingszeit</li>
    <ul>
      <li>Stillprobleme, Schrei- und Spuckkinder</li>
      <li>Schlafthematiken</li>
      <li>Unruhe und Schreckhaftigkeit</li>
    </ul>
    <li>Vorbereitung auf die Geburt und auf die Elternschaft</li>
    <li>unerfüllter Kinderwunsch</li>
  </ul>

  <h2>Entwicklung und Lernen des Kindes</h2>
  <ul>
    <li>
      Auffälligkeiten in der (frühkindlichen) Verhaltens- und
      Bewegungsentwicklung
    </li>
    <li>Restreaktionen frühkindlicher Reflexe</li>
    <li>Lern- und Konzentrationsprobleme</li>
    <li>Wahrnehmungsschwierigkeiten</li>
  </ul>

  <h2>Psycho-Soziale Lebensbereiche, Berufsleben</h2>
  <ul>
    <li>
      Konflikte: in der Erziehung, als Eltern, mit den Eltern, in der Familie,
      unter Geschwistern, als Paar
    </li>
    <li>Trennung/Scheidung</li>
    <li>Integrationsprozess von Pflege- und Adoptivkindern</li>
    <li>Mobbing am Arbeitsplatz und in der Schule</li>
    <li>(Emotionale) Stressbewältigung</li>
  </ul>

  <p>
    Nur nach Absprache und in Zusammenarbeit mit Ärzten und Psychotherapeuten
    bei körperlichen Erkrankungen, psychischen und psychosomatischen Störungen
    Begleitung bestehender medizinischer/psychotherapeutischer Maßnahmen, z.B.
    bei Krebs, chronischen Krankheiten, bei Ängsten, Zwängen, leichten
    Depressionen, Suchtverhalten, Essstörungen, Burnout, ADHS/ADS,
    Kinderwunschbehandlungen physiologische Traumata: z.B. Unfälle, Stürze,
    Brüche, Narben, Operationen und emotionale Traumata Ernährung, Gewicht,
    Nahrungsunverträglichkeiten
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home"
});
</script>

<style lang="scss" scoped></style>
