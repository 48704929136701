<template>
  <div id="biography">
    <img
      src="../assets/Ingrid_Duennewald.png"
      width="106"
      height="130"
      alt="Bild von Ingird Dünnewald"
    />
    <p>
      Im Grundberuf bin ich Erzieherin und habe mich im Sommer 2001
      selbstständig gemacht. Ich habe durch berufsbegleitende, zertifizierte
      Weiterbildungen Zusatzqualifikationen erworben.
      <ul>
        <li>Begleitende Kinesiologin DGAK</li>
        <li>Systemische Familientherapeutin</li>
        <li>Supervisiorin (ifs)</li>
        <li>TRE- Provider, Zertifikat A u. B (niba e.V.)</li>
      </ul> 
      Diese methodischen Fachrichtungen biete ich in meiner Praxis BalanceRäume und in Institutionen an.
    </p>
    <p>
      Unabhängig davon, wieso Menschen, aus dem Gleichgewicht geraten sind - es
      ist mir eine große Freude - Sie, Ihr Kind, Ihre Familie, Sie und Ihren
      Partner oder Systeme, Gruppen, Teams dabei zu begleiten, (wieder) in die
      Balance zu kommen.
    </p>
    <br />
    <p>Fortbildungen - Vorträge - Seminare - Projekte - Workshops</p>
    <br />
    <p>
      Rufen Sie mich gerne an, Telefon: <a href="tel:+4925321778">02532 - 1778</a>. Ich beantworte Ihre Fragen und/oder vereinbare
      einen Termin mit Ihnen.
    </p>
    <br />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Biography"
});
</script>

<style scoped lang="scss">
#biography {
  text-align: left;
  padding: 0 6px 0 6px;
}

img {
  float: left;
  min-width: 100px;
  max-width: 100px;
  padding-right: 6px;
  margin: 6px 0 6px 6px;
}
</style>
